import WhatsAppApi from "services/whatsapp";
import swalState from "utility/swal";
import { message } from "antd";
import { removeEmptyValues } from "utility/helperFunctions";

function handleError(err) {
  console.error("Error in whatsapp actions: ", err);
}

export function setMessagesList(payload) {
  return {
    type: "SET_MESSAGES_LIST",
    payload,
  };
}

export function updateMessageFilter(payload) {
  return {
    type: "UPDATE_MESSAGES_FILTER",
    payload,
  };
}

export function setMessageFilter(payload) {
  return {
    type: "SET_MESSAGES_FILTER",
    payload,
  };
}

export const getMessageListAction = (filters) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await WhatsAppApi.getMessageList(removeEmptyValues(filters));
    if (response) {
      swalState.hideLoader();
      const { data, count, totalCount, prev, next, pageSize } = response;
      dispatch(
        setMessagesList({
          data,
          pagination: {
            count,
            totalCount,
            prev,
            next,
            pageSize,
          },
        })
      );
    }
  } catch (err) {
    message.error(err.message);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};
export const updateFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(updateMessageFilter(update));
  } catch (err) {
    handleError(err);
  }
};

export const setFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(setMessageFilter(update));
  } catch (err) {
    handleError(err);
  }
};
