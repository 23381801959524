const initialState = {
  rideList: [],
  filters: {
    page: 0,
    sort: {
      startDateTime : "desc",
    }
  },
  pagination: {
    count: 0,
    totalCount: 0,
    prev: null,
    next: null,
    pageSize: 0,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_RIDE_LIST":
        return setRideList(state, action);
    case "UPDATE_RIDE_FILTER": 
        return updateRideFilter(state, action);
    case "SET_RIDE_FILTER": 
        return setRideFilter(state, action);
    default:
      return state;
  }
}

function setRideList(state, action) {
  return {
    ...state,
    rideList: action.payload ? action.payload.data : initialState.rideList,
    pagination: action.payload ? action.payload.pagination : initialState.pagination,
  };
}

function updateRideFilter(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    }
  };
}

function setRideFilter(state, action) {
  return {
    ...state,
    filters: action.payload ? action.payload : initialState.filters
  };
}
