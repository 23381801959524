import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import { deleteCarouselSlider, getCarouselList } from "actions/carousel";

const DeleteSliderModal = ({
  visible,
  setVisible,
  slider,
  filters,
  fetchCarouselList,
  deleteCarouselSlider,
}) => {
  return (
    <Modal
      title="Delete Slider"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="submit"
          primary
          color={Theme.blueColor}
          onClick={async () => {
            const { carouselId, id } = slider;
            setVisible(false);
            await deleteCarouselSlider({ carouselId, id });
            fetchCarouselList(filters);
          }}
        >
          Delete
        </Button>,
      ]}
    >
      Are you sure you want to delete this slider?
    </Modal>
  );
};

function mapStateToProps(state) {
  const {
    Carousel: { filters },
  } = state;
  return {
    filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCarouselList: bindActionCreators(getCarouselList, dispatch),
    deleteCarouselSlider: bindActionCreators(deleteCarouselSlider, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSliderModal);
