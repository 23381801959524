import styled from "styled-components";
import { Theme } from "static/coreStyles/theme.styled";

export const EmptyStateWrap = styled.div`
  min-height: calc(100vh - 380px);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    text-transform: capitalize;
    color: ${Theme.blueColor};
    font-size: 20px;
  }
`;
