import React, { useState } from "react";
import { Td, TableList, Status } from "static/coreStyles/table.styled";
import { Button, Avatar,} from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import { getInitials } from "utility/helperFunctions";
import { formatToDateString } from "utility/helperFunctions";
import { riders } from "../data";


export const RideListData= ({
  data: {
    key,
    riderName,
    contactNo,
    email,
    emergencyContact,
    bloodGroup,
    rideStatus
  },
}) => {
  

  if (!key) return null;

  return (
    
      <TableList > 
        
        <Td width="14%">{riderName && <span>{riderName}</span>}</Td>

        <Td width="10%">
          {contactNo && <span>{contactNo}</span>}
        </Td>

        <Td width="10%">
          {email && <span>{email}</span>}
        </Td>

        <Td width="14%">
          {emergencyContact && <span>{emergencyContact}</span>}
        </Td>

        <Td width="14%">
          {bloodGroup && <span>{bloodGroup}</span>}
        </Td>

        <Td width="8%">
          <Status status={rideStatus}>{rideStatus}</Status>
        </Td>

        
      </TableList>
    
  );
};
