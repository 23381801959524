const initialState = {
  currentUser: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return setCurrentUser(state, action);
    default:
      return state;
  }
};

function setCurrentUser(state, action) {
  return {
    ...state,
    currentUser: action.payload,
  };
}
