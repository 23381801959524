import { Api } from "../config/request";

export const getCarouselList = (payload) => {
  return Api.getRequest(
    `admins/carousel?location=${payload.location}&name=${payload.name}`
  );
};

export const deleteCarouselSlider = (payload) => {
  return Api.deleteRequest(
    `admins/carousel/${payload.carouselId}/slider/${payload.id}`
  );
};

export const addCarouselSlider = (payload) => {
  return Api.postRequest(
    `admins/carousel/${payload.carouselId}/slider`,
    payload.data
  );
};

export const editCarouselSlider = (payload) => {
  return Api.putRequest(
    `admins/carousel/${payload.carouselId}/slider/${payload.id}`,
    payload.data
  );
};

export const getImageLink = (payload) => {
  return Api.filePostRequest(
    `admins/files`,
    payload,
    false,
    "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s"
  );
};

const CarouselAPI = {
  getCarouselList,
  deleteCarouselSlider,
  addCarouselSlider,
  editCarouselSlider,
  getImageLink,
};

export default CarouselAPI;
