export const tableHead = [
  {
    title: "Group Name",
    width: "16%", // Maintain width
    sortKey: "name",
    
  },
  {
    title: "Admin",
    width: "9%",
    textAlign: "center",
  },
  {
    title: "Number",
    width: "10%",
    textAlign: "center",
  },
  {
    title: "Based in",
    width: "8%",
    textAlign: "center",
  },
  {
    title: "Date Created",
    sortKey: "createdAt",
    width: "10%",
    textAlign: "center",
  },
  {
    title: "Members",
    sortKey: "members",
    width: "6%",
    textAlign: "center",
  },
  {
    title: "Distance",
    sortKey: "distance",
    width: "6%",
    textAlign: "center",
  },
  {
    title: "Rides",
    sortKey: "rides",
    width: "6%",
    textAlign: "center",
  },
  {
    title: "Public",
    width: "7%",
    textAlign: "center",
  },
  {
    title: "Verified",
    sortKey: "verified",
    width: "9%",
    textAlign: "center",
  },
  {
    title: "Payment",
    sortKey: "payment",
    width: "8%",
    textAlign: "center",
  },
  {
    title: "Action",
    sortKey: "",
    width: "5%",
    textAlign: "center",
  },
];
