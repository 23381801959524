import { Api } from "../config/request";

export const userLogin = (payload) => {
  let url = `auth/login`;
  return Api.postRequest(url, payload)
}

export const userLogout = (payload) => {
  let url = `admins/logout`;
  return Api.postRequest(url, payload)
}

const Login = {
  userLogin,
  userLogout
};

export default Login;
