import React from 'react';
import RootRouter from "../src/router/rootRouter";
// import "antd/dist/antd.css";
import './App.css';
import Header from 'components/Header';

function App() {
  return (
    <div className="App">
       <Header />
       <RootRouter />
    </div>
  );
}

export default App;
