import React, { useState } from "react";
import { Td, TableList } from "static/coreStyles/table.styled";
import { Avatar, Button, Modal, Switch, Typography } from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import { getInitials } from "utility/helperFunctions";
import { formatToDateString } from "utility/helperFunctions";
import { useDispatch } from 'react-redux';
import { updateGroupDetails } from "actions/group";
import { EyeOutlined } from '@ant-design/icons';

const { Text, Paragraph } = Typography;

export const GroupList = ({
  data: {
    id,
    groupId,
    name,
    foundingPlace,
    admins,
    foundingYear,
    groupPicture,
    memberCount,
    totalKm,
    totalRides,
    createdAt,
    isPrivate,
    isVerified,
    isPaymentEnabled,
  },
}) => {
  const [open, setOpen] = useState(false);
  const [verifyGroup, setVerifyGroup] = useState(isVerified);
  const [enablePayment, setEnablePayment] = useState(isPaymentEnabled);

  const [tempVerifyGroup, setTempVerifyGroup] = useState(isVerified);
  const [tempEnablePayment, setTempEnablePayment] = useState(isPaymentEnabled);

  const dispatch = useDispatch();

  if (!id) return null;

  const handleClickOpen = () => {
    setTempVerifyGroup(verifyGroup); // Initialize temporary state with current state
    setTempEnablePayment(enablePayment); // Initialize temporary state with current state
    setOpen(true);
  };
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {

    setVerifyGroup(tempVerifyGroup);
    setEnablePayment(tempEnablePayment);
    
    const updates = { isVerified: tempVerifyGroup, isPaymentEnabled: tempEnablePayment };
    dispatch(updateGroupDetails(id, updates));
    handleClose();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TableList style={{ width: '100%', tableLayout: 'fixed' }}>
        <Td width="16%">
          <div style={{ display: "flex", paddingRight: 15, alignItems: "center" }}>
            <div style={{ marginRight: 15 }}>
              {groupPicture ? (
                <Avatar size={35} src={groupPicture} alt="grouppicture" />
              ) : (
                <Avatar style={{ backgroundColor: Theme.blueColor }} size={35}>
                  {getInitials(name)}
                </Avatar>
              )}
            </div>
            <div>
              {name && <Text >{name}</Text>}
              {groupId && <Text type="secondary" style={{ display: "block", fontSize: "0.75rem" }}>{groupId}</Text>}
            </div>
          </div>
        </Td>

        <Td width="9%" style={{ verticalAlign: "left", fontSize: '0.8rem' }}>
          {Array.isArray(admins) && admins.length > 0 && (
            <>
              <Text>{`${admins[0].firstName} ${admins[0].lastName}`}</Text>
              <Text type="secondary" style={{ display: "block", fontSize: "0.75rem", marginTop: 5 }}>
                {admins[0].userName}
              </Text>
            </>
          )}
        </Td>

        <Td width="10%" style={{ verticalAlign: "left", fontSize: '0.8rem' }}>
          {Array.isArray(admins) && admins.length > 0 && (
            <Text>{`+${admins[0].countryCode} ${admins[0].phoneNumber}`}</Text>
          )}
        </Td>

        <Td width="8%" style={{ verticalAlign: "middle", fontSize: '0.8rem'}}>
          {foundingPlace && <Text>{foundingPlace.displayName}</Text>}
        </Td>

        <Td width="10%" style={{ verticalAlign: "middle", fontSize: '0.8rem'}}>
          {createdAt && <Text>{formatToDateString(createdAt)}</Text>}
        </Td>

        <Td width="6%" style={{ verticalAlign: "middle", fontSize: '0.8rem' }}>
          {memberCount && <Text>{memberCount}</Text>}
        </Td>

        <Td width="6%" style={{ verticalAlign: "middle", fontSize: '0.8rem'}}>
          {totalKm && <Text>{totalKm}</Text>}
        </Td>

        <Td width="6%" style={{ verticalAlign: "middle", fontSize: '0.8rem'}}>
          {totalRides && <Text>{totalRides}</Text>}
        </Td>

        <Td width="7%" style={{ verticalAlign: "middle", fontSize: '0.8rem' }}>
          <Text>{isPrivate ? "🔒 Private" : "✅ Public"}</Text>
        </Td>

        <Td width="9%" style={{ verticalAlign: "middle" }}>
          <Text
            style={{
              color: isVerified ? 'green' : 'red',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              backgroundColor: isVerified ? 'rgba(0, 128, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
              border: `1px solid ${isVerified ? 'green' : 'red'}`,
              borderRadius: '50px',
              padding: '5px 12px',
              display: 'inline-block',
            }}
          >
            {isVerified ? "Verified" : "Not Verified"}
          </Text>
        </Td>

        <Td width="8%" style={{ verticalAlign: "middle" }}>
          <Text
            style={{
              color: isPaymentEnabled ? 'green' : 'red',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              backgroundColor: isPaymentEnabled ? 'rgba(0, 128, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
              border: `1px solid ${isPaymentEnabled ? 'green' : 'red'}`,
              borderRadius: '50px',
              padding: '5px 12px',
              display: 'inline-block',
            }}
          >
            {isPaymentEnabled ? "Approved" : "Pending"}
          </Text>
        </Td>

        <Td width="5%" style={{ verticalAlign: "middle" }}>
          <Button icon={<EyeOutlined />} onClick={handleClickOpen} />
          <Modal
            title={<span style={{ fontSize: '24px', fontWeight: 'bold', color: 'grey' }}>Settings</span>}
            visible={open}
            onOk={handleSave}
            onCancel={handleClose}
            okText="Save"
            cancelText="Cancel"
            width={450} 
            bodyStyle={{ padding: '20px' }}
            footer={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  onClick={handleClose}
                  style={{
                    width: '45%',
                    borderRadius: '25px',
                    borderColor: 'white',
                    color: '#6528D8',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{
                    width: '45%',
                    borderRadius: '25px',
                    backgroundColor: '#6528D8',
                  }}
                >
                  Save
                </Button>
              </div>
            }
            centered={true}
          >
            <div style={{ marginBottom: '20px' }}>
              <label style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Verify Group</span>
                <Switch checked={tempVerifyGroup} onChange={() => setTempVerifyGroup(!tempVerifyGroup)} />
              </label>
              <Paragraph type="secondary" style={{ fontSize: '0.75rem' }}>
                This will make the group verified by Asteride.
              </Paragraph>
            </div>

            <div>
              <label style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Enable Payment</span>
                <Switch checked={tempEnablePayment} onChange={() => setTempEnablePayment(!tempEnablePayment)} />
              </label>
              <Paragraph type="secondary" style={{ fontSize: '0.75rem' }}>
                This will allow admins to make a paid ride.
              </Paragraph>
            </div>
          </Modal>
        </Td>
      </TableList>
    </div>
  );
};
