const initialState = {
  usersList: [],
  filters: {
    page: 0,
    sort: {
      createdAt: 'desc',
    },
  },
  pagination: {
    count: 0,
    totalCount: 0,
    prev: null,
    next: null,
    pageSize: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_USER_LIST":
      return setUserList(state, action);
    case "UPDATE_USER_FILTER":
      return updateUserFilter(state, action);
    case "SET_USER_FILTER":
      return setUserFilter(state, action);
    default:
      return state;
  }
}

function setUserList(state, action) {
  return {
    ...state,
    usersList: action.payload ? action.payload.data : initialState.usersList,
    pagination: action.payload
      ? action.payload.pagination
      : initialState.pagination,
  };
}

function updateUserFilter(state, action) {
  if (action.payload) {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    };
  }
  return state;
}

function setUserFilter(state, action) {
  return {
    ...state,
    filters: action.payload ? action.payload : initialState.filters,
  };
}
