import React from "react";
import { Td, TableList } from "static/coreStyles/table.styled";
import countryData from "static/data/country.json";
import { calculateAge } from "utility/helperFunctions";
import { formatToDateString } from "utility/helperFunctions";
import { Theme } from "static/coreStyles/theme.styled";
import { getInitials } from "utility/helperFunctions";
import { Avatar } from "antd";
export const UserList = ({
  data: {
    id,
    firstName,
    lastName,
    userName,
    dob,
    bloodGroup,
    groupsCount,
    bikesCount,
    ridesCount,
    totalRidesCount,
    alphaCode,
    phoneNumber,
    countryCode,
    profilePic,
    createdAt,
  },
}) => {
  if (!id) return null;

  return (
    <TableList>
      <Td width="20%">
        <div style={{ display: "flex" }}>
          <div className="info" style={{ display: "flex", marginRight: 10 }}>
            {profilePic ? (
              <Avatar size={27} src={profilePic} alt="profilePic" />
            ) : (
              <Avatar style={{ backgroundColor: Theme.blueColor }} size={27}>
                {getInitials(firstName, lastName)}
              </Avatar>
            )}
          </div>
          <div>
            {firstName && (
              <span>{String(firstName + " " + lastName).trim()}</span>
            )}
            {userName && <em>{userName}</em>}
          </div>
        </div>
      </Td>
      <Td width="15%">
        {phoneNumber && (
          <span>
            {"+"}
            {countryCode}{" "}{phoneNumber}
          </span>
        )}
      </Td>
      <Td width="12%">
        {createdAt && <span>{formatToDateString(createdAt)}</span>}
      </Td>
      <Td width="8%">{dob && <span>{calculateAge(dob)}</span>}</Td>
      <Td width="10%">
        {bloodGroup && (
          <span style={{ display: "flex" }}>
            {bloodGroup}
            <span style={{ textTransform: "lowercase" }}>ve</span>
          </span>
        )}
      </Td>
      <Td width="10%">{groupsCount && <span>{groupsCount}</span>}</Td>
      <Td width="10%">{ridesCount && <span>{ridesCount}</span>}</Td>
      <Td width="10%">{totalRidesCount && <span>{totalRidesCount}</span>}</Td>
      <Td width="12%">
        {countryData[countryCode] && (
          <span>
            {countryData[countryCode].flag} {countryData[countryCode].name}
          </span>
        )}
      </Td>
      <Td width="8%">{bikesCount && <span>{bikesCount}</span>}</Td>
    </TableList>
  );
};
