import carouselFilterData from "static/data/carouselFilter.json";
const initialState = {
  carouselList: [],
  currCarouselId: null,
  filters: {
    // page: 0,
    location: "Home",
    name: carouselFilterData["Home"][0],
    // sort: {
    //   seqNum: "asc",
    // },
  },
  //   pagination: {
  //     count: 0,
  //     totalCount: 0,
  //     prev: null,
  //     next: null,
  //     pageSize: 0,
  //   },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_CAROUSEL_LIST":
      return setCarouselList(state, action);
    case "UPDATE_CAROUSEL_FILTER":
      return updateCarouselFilter(state, action);
    case "SET_CAROUSEL_FILTER":
      return setCarouselFilter(state, action);
    case "SET_CAROUSEL_ID":
      return setCarouselId(state, action);
    default:
      return state;
  }
}

function setCarouselList(state, action) {
  return {
    ...state,
    carouselList: action.payload
      ? action.payload.data
      : initialState.carouselList,
    pagination: action.payload
      ? action.payload.pagination
      : initialState.pagination,
  };
}

function updateCarouselFilter(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  };
}

function setCarouselFilter(state, action) {
  return {
    ...state,
    filters: action.payload ? action.payload : initialState.filters,
  };
}

function setCarouselId(state, action) {
  return {
    ...state,
    currCarouselId: action.payload
      ? action.payload
      : initialState.currCarouselId,
  };
}
