import React, { Component } from "react";
import {
  HeaderContainer,
  HeaderTitle,
  Logo,
  Titles,
  ProfileContainer,
  HeaderWrap,
  LogoutWrap,
  TitleWrap,
} from "./Header.styled";
// import { withRouter } from "react-router-dom";
import { HeaderTitles } from "./HeaderTitles";
import { Container } from "static/coreStyles/global.styled";
import { Avatar, Popover } from "antd";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userLogout } from "actions/login";
import { getInitials } from "utility/helperFunctions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = { peopleActive: false, show: false, visible: false };
    this.exclusionPaths = ["/login"];
  }
  isActive = (path) => {
    return this.props.location.pathname.includes(path);
  };
  redirect = (path) => {
    if (this.props.location.pathname !== path) this.props.history.push(path);
  };

  handleLogout = () => {
    this.hide();
    this.props.userLogout(this.props.history);
  };
  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };
  render() {
    const currentUser = this.props.currentUser;
    if (!currentUser) return null;
    return (
      <>
        {this.exclusionPaths.indexOf(this.props.location.pathname) < 0 && (
          <HeaderWrap>
            <Container>
              <HeaderContainer>
                <Logo src={require("static/images/logo.svg")} alt="logo" />
                <Titles>
                  {HeaderTitles.map((title, index) => {
                    return (
                      <HeaderTitle
                        key={index}
                        active={this.isActive(title.url)}
                      >
                        <div
                          onClick={() => {
                            this.redirect(title.url);
                          }}
                          className="nav-link"
                        >
                          <i>
                            {this.isActive(title.url) ? (
                              <img src={title.activeIcon} alt="" />
                            ) : (
                              <img src={title.inactiveIcon} alt="" />
                            )}
                          </i>
                          <span>{title.title}</span>
                          {this.props[String(title.title).toLowerCase()] ? (
                            <small>
                              {this.props[String(title.title).toLowerCase()]}
                            </small>
                          ) : null}
                        </div>
                      </HeaderTitle>
                    );
                  })}
                </Titles>
                <ProfileContainer>
                  <Popover
                    content={
                      <LogoutWrap onClick={this.handleLogout}>
                        Logout
                      </LogoutWrap>
                    }
                    title={
                      <TitleWrap>
                        <h3>
                          {currentUser && currentUser.email
                            ? currentUser.email
                            : ""}
                        </h3>
                        <h4>
                          {(currentUser.firstName || "") +
                            " " +
                            (currentUser.lastName || "")}
                        </h4>
                      </TitleWrap>
                    }
                    trigger="click"
                    placement="bottomRight"
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <Avatar icon={currentUser.profilePic}>
                      {getInitials(currentUser.firstName, currentUser.lastName)}
                    </Avatar>
                  </Popover>
                </ProfileContainer>
              </HeaderContainer>
            </Container>
          </HeaderWrap>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: bindActionCreators(userLogout, dispatch),
  };
}
function mapStateToProps({
  Login: { currentUser },
  User: {
    pagination: { totalCount: userTotalCount },
  },
  Ride: {
    pagination: { totalCount: rideTotalCount },
  },
  Group: {
    pagination: { totalCount: groupTotalCount },
  },
}) {
  return {
    currentUser,
    users: userTotalCount,
    rides: rideTotalCount,
    groups: groupTotalCount,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
