export const Theme = {
  white: "#fff",
  black: "#000000",
  blueColor: "#6528d8",
  primaryColor: "#333",
  secondaryColor: "rgba(19,20,21,0.5)",
  greyColor:"#707070"
};

export const Size = {
  xxxl: "30px",
  xxl: "26px",
  xl: "24px",
  bxl: "22px",
  bl: "20px",
  lg: "18px",
  base: "16px",
  sm: "14px",
  xs: "12px",
  headerHeight: "60px"
};

export const Font = {
  regular: "cabin-Regular",
  bold: "cabin-bold",
  medium: "cabin-medium",
};
