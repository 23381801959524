import Swal from "sweetalert2";
const swalState = {
  showLoader: function () {
    Swal.fire({
      html:
        '<div class="lds-css" style={{ minWidth: 200, minHeight: 200 }}><div style={{ width: "100%", height: "100%" }} class="lds-dual-ring"><div></div></div></div>',
      showConfirmButton: false,
      backdrop: true,
      allowOutsideClick: false,
      background: "transparent"
    });
  },
  hideLoader: function () {
    Swal.close();
  },
};
export default swalState;
