import React, { useState, useEffect } from "react";
import {
  Container,
  PageHeader,
  TitleHead,
  PageWrap,
  Button,
} from "static/coreStyles/global.styled";
import {
  TableWrap,
  TableHeadWrap,
  TableContentWrap,
} from "static/coreStyles/table.styled";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmptyState from "components/emptyState";
import Pagination from "components/pagination";
import MessagesList from "./MessagesList/MessagesList";
import * as whatsAppActions from "actions/whatsapp";
import { HeaderButtons } from "components/Header";
import Search from "components/Search";
import { useDebounce } from "utility/helperFunctions";
import { tableHead } from "./data";
import TableHead from "components/TableHead";
import { omit } from "lodash";
import { useSetState } from "utility/helperFunctions";
import { Modal } from "antd";

const WhatsAppMessagesTable = ({
  messagesList,
  fetchMessagesList,
  filters,
  updateMessageFilter,
  setMessageFilter,
  pagination,
}) => {
  const sortMap = filters.sort || {};
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchMessagesList(filters);
  }, [filters, fetchMessagesList]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      updateMessageFilter({
        query: debouncedSearchTerm,
      });
    } else {
      updateMessageFilter({
        query: "",
      });
    }
  }, [debouncedSearchTerm, updateMessageFilter]);

  const handleSort = (key, value) => {
    if (value === "") {
      const sortKeys = omit(filters.sort, key);
      updateMessageFilter({
        sort: sortKeys,
      });
    } else {
      updateMessageFilter({
        sort: {
          ...(filters.sort ? filters.sort : {}),
          [key]: value,
        },
      });
    }
  };

  return (
    <PageWrap>
      <Container>
        <PageHeader>
          <TitleHead>
            WhatsApp Messages
            <Search
              setSearchTerm={setSearchTerm}
              onFilterClick={() => {
                setModalVisible(false);
              }}
              filters={filters}
              setFilter={setMessageFilter}
            />
            <HeaderButtons />
          </TitleHead>
        </PageHeader>
        {messagesList && messagesList.length > 0 ? (
          <TableWrap>
            <TableHeadWrap>
              {tableHead.map((item, index) => {
                const sortingKey =
                  item.sortKey &&
                  sortMap &&
                  sortMap.hasOwnProperty(item.sortKey)
                    ? sortMap[item.sortKey]
                    : null;
                return (
                  <TableHead
                    data={item}
                    key={index}
                    isSorted={!!item.sortKey}
                    handleSort={() => {
                      if (sortingKey === "desc") {
                        handleSort(item.sortKey, "asc");
                      } else if (sortingKey === "asc") {
                        handleSort(item.sortKey, "");
                      } else {
                        handleSort(item.sortKey, "desc");
                      }
                    }}
                    sort={sortingKey}
                  />
                );
              })}
            </TableHeadWrap>
            <TableContentWrap>
              {messagesList.map((item, index) => {
                return <MessagesList data={item} key={index} />;
              })}
            </TableContentWrap>
          </TableWrap>
        ) : (
          <EmptyState />
        )}
        <Pagination
          page={filters.page}
          pagination={pagination}
          updatePage={(newPage) => {
            updateMessageFilter({ page: newPage });
          }}
        />
        <FilterModal
          filters={filters}
          visible={modalVisible}
          setVisible={setModalVisible}
          setFilter={setMessageFilter}
          updateFilter={updateMessageFilter}
        />
      </Container>
    </PageWrap>
  );
};

function FilterModal({ visible, setVisible, setFilter }) {
  const [state, setState] = useSetState({});
  return (
    <Modal
      title="Apply Filter"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setFilter(null);
            setState({
              dateRange: null,
              distanceRange: null,
              memberRange: null,
              rideCountRange: null,
            });
            setVisible(false);
          }}
        >
          Reset
        </Button>,
      ]}
    ></Modal>
  );
}
function mapStateToProps(state) {
  const {
    Whatsapp: { messagesList, filters, pagination },
  } = state;
  return { messagesList, filters, pagination };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMessagesList: bindActionCreators(
      whatsAppActions.getMessageListAction,
      dispatch
    ),
    setMessageFilter: bindActionCreators(
      whatsAppActions.setMessageFilter,
      dispatch
    ),
    updateMessageFilter: bindActionCreators(
      whatsAppActions.updateMessageFilter,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsAppMessagesTable);
