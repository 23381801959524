import { Api } from "../config/request";

export const getUsersList = (payload) => {
  return Api.postRequest(`admins/users`, payload);
};

const UserAPI = {
  getUsersList
};

export default UserAPI;
