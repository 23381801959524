import { useEffect, useState, useCallback } from "react";
import { storage as LocalStorage } from "services/config/storage";
import moment, { isMoment } from "moment";
import { pickBy, identity } from "lodash";

export const validateEmail = (email) => {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export function logout(history) {
  localStorage.clear();
  if (history) {
    history.push("/login");
  } else {
    window.location.href = "/";
  }
}

export function isAuthenticated() {
  return LocalStorage.fetch.authToken() ? true : false;
}

export function isString(value) {
  if (typeof value === "string" || value instanceof String) {
    return true;
  } else {
    return false;
  }
}

export function getInitials(firstName, lastName = "") {
  const name = `${firstName || ""} ${lastName || ""}`.trim().toUpperCase();
  if (!name || !isString(name)) {
    return "N/A";
  }
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export const useSetState = (initialState) => {
  const [state, set] = useState(initialState);
  const setState = useCallback(
    (patch) => {
      set((prevState) =>
        Object.assign(
          {},
          prevState,
          patch instanceof Function ? patch(prevState) : patch
        )
      );
    },
    [set]
  );

  return [state, setState];
};

export function calculateAge(birthday) {
  if (!isMoment(birthday)) {
    birthday = moment(birthday);
  }
  return moment().diff(birthday, "years");
}

export const formatToDateString = (dateString) => {
  const dt = new Date(dateString);
  const date = dt.toLocaleString("default", { day: "numeric" });
  const month = dt.toLocaleString("default", { month: "long" }).slice(0, 3);
  const year = dt.toLocaleString("default", { year: "numeric" }).slice(-2);
  return `${date} ${month}' ${year}`;
};

export const removeEmptyValues = (object) => pickBy(object, identity);

export const formatToDateTimeString = (utcDateString) => {
  // Parse the input date string
  const date = new Date(utcDateString);

  // Convert to IST (UTC+5:30)
  const istOffset = 5.5 * 60 * 60000; // 5.5 hours in milliseconds
  const istDate = new Date(date.getTime() + istOffset);

  // Format the date and time in a readable format
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  // Return the formatted date string in IST
  return istDate.toLocaleString("en-IN", options);
};
