import { Api } from "../config/request";


export const getGroupList = (payload) => {
  return Api.postRequest(`admins/groups`, payload);
};


export const updateGroupDetails = (id, payload) => {
  return Api.putRequest(`admins/${id}`, payload);
};


const GroupAPI = {
  getGroupList,
  updateGroupDetails,
};

export default GroupAPI;
