import React from "react";

import {Container, GoBack, Title, Subtitle, Wrapper  } from "./fourohfour.styled";

import { connect } from "react-redux";

function FourOhFour({ history }) {
    return (
      <Wrapper>
        <Container>
          <Title>404</Title>
          <Subtitle>Page not found</Subtitle>
          <GoBack
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </GoBack>
        </Container>
      </Wrapper>
    );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FourOhFour);
