import UserAPI from "services/user";
import swalState from "utility/swal";
import { message } from "antd";
import { removeEmptyValues } from "utility/helperFunctions";

function handleError(err) {
  console.error("Error in users actions: ", err);
}

export function setUserList(payload) {
  return {
    type: "SET_USER_LIST",
    payload,
  };
}
export function updateUserFilter(payload) {
  return {
    type: "UPDATE_USER_FILTER",
    payload,
  };
}

export function setUserFilter(payload) {
  return {
    type: "SET_USER_FILTER",
    payload,
  };
}


export const getUserListAction = (filters) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await UserAPI.getUsersList(removeEmptyValues(filters));
    if (response) {
      swalState.hideLoader();
      const { data, count, totalCount, prev, next, pageSize } = response;
      dispatch(
        setUserList({
          data,
          pagination: {
            count,
            totalCount,
            prev,
            next,
            pageSize,
          },
        })
      );
    }
  } catch (err) {
    message.error(err.message);
    handleError(err);
    swalState.hideLoader();
  }
};

export const updateFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(updateUserFilter(update));
  } catch (err) {
    handleError(err);
  }
};

export const setFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(setUserFilter(update));
  } catch (err) {
    handleError(err);
  }
};
