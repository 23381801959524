import React from "react";
import { Switch, withRouter, Redirect } from "react-router-dom";
import { PrivateRoute } from "utility/routes";
import Users from "views/users";
import Rides from "views/rides";
import Ride from "views/ride";
import Groups from "views/groups";
import FourOhFour from "views/fourohfour";
import WhatsAppMessagesTable from "views/whatsapp";
import Carousel from "views/carousel";

function Container() {
  return (
    <div className="wrapper">
      <Switch>
        <Redirect exact from="/" to="/users" />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/rides" component={Rides} />
        <PrivateRoute exact path="/ride" component={Ride} />
        <PrivateRoute exact path="/groups" component={Groups} />
        <PrivateRoute
          exact
          path="/whatsapp"
          component={WhatsAppMessagesTable}
        />
        <PrivateRoute exact path="/carousel" component={Carousel} />
        <PrivateRoute component={FourOhFour} />
      </Switch>
    </div>
  );
}
export default withRouter(Container);
