import swalState from "utility/swal";
import { message } from "antd";
import CarouselAPI from "services/carousel";
import { removeEmptyValues } from "utility/helperFunctions";

function handleError(err) {
  console.error("Error in users actions: ", err);
}

export function setCarouselList(payload) {
  return {
    type: "SET_CAROUSEL_LIST",
    payload,
  };
}

export function updateCarouselFilter(payload) {
  return {
    type: "UPDATE_CAROUSEL_FILTER",
    payload,
  };
}

export function setCarouselFilter(payload) {
  return {
    type: "SET_CAROUSEL_FILTER",
    payload,
  };
}

export function setCarouselId(payload) {
  return {
    type: "SET_CAROUSEL_ID",
    payload,
  };
}

export const getCarouselList = (filters) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await CarouselAPI.getCarouselList(
      removeEmptyValues(filters)
    );
    console.log(response);
    if (response) {
      const { data } = response;
      dispatch(setCarouselId(data.id));
      const sliders = data.sliders;
      dispatch(
        setCarouselList({
          data: sliders,
        })
      );
    }
  } catch (err) {
    message.error(err.message);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};

export const addCarouselSlider = (carouselId, data) => async (dispatch) => {
  try {
    swalState.showLoader();
    await CarouselAPI.addCarouselSlider({ carouselId, data });
  } catch (err) {
    message.error(err.message);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};

export const editCarouselSlider =
  (carouselId, id, data) => async (dispatch) => {
    try {
      swalState.showLoader();
      await CarouselAPI.editCarouselSlider({
        carouselId,
        id,
        data,
      });
    } catch (err) {
      message.error(err.message);
      handleError(err);
    } finally {
      swalState.hideLoader();
    }
  };

export const deleteCarouselSlider =
  ({ carouselId, id }) =>
  async (dispatch) => {
    try {
      swalState.showLoader();
      await CarouselAPI.deleteCarouselSlider({ carouselId, id });
    } catch (err) {
      message.error(err.message);
      handleError(err);
    } finally {
      swalState.hideLoader();
    }
  };

export const getImageLink = (data) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await CarouselAPI.getImageLink(data);
    return response;
  } catch (err) {
    message.error(err.message);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};

export const updateFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(updateCarouselFilter(update));
  } catch (err) {
    handleError(err);
  }
};

export const setFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(setCarouselFilter(update));
  } catch (err) {
    handleError(err);
  }
};
