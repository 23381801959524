const initialState = {
  messagesList: [],
  filters: {
    page: 0,
    sort: {
      createdAt: "desc",
    },
  },
  pagination: {
    count: 0,
    totalCount: 0,
    prev: null,
    next: null,
    pageSize: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_MESSAGES_LIST":
      return setMessagesList(state, action);
    case "UPDATE_MESSAGES_FILTER":
      return updateMessageFilter(state, action);
    case "SET_MESSAGES_FILTER":
      return setMessageFilter(state, action);
    default:
      return state;
  }
}
function setMessagesList(state, action) {
  return {
    ...state,
    messagesList: action.payload
      ? action.payload.data
      : initialState.messagesList,
    pagination: action.payload
      ? action.payload.pagination
      : initialState.pagination,
  };
}

function updateMessageFilter(state, action) {
  if (action.payload) {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    };
  }
  return state;
}

function setMessageFilter(state, action) {
  return {
    ...state,
    filters: action.payload ? action.payload : initialState.filters,
  };
}
