import React from "react";
import { Switch } from "react-router-dom";
import { PublicRoute } from "utility/routes";
import Login from "views/Login";
import AppRouter from "router/appRouter";

function RootRouter() {
  return (
    <Switch>
      {/* Add those routes here which do not need any layout such as login etc. */}
      <PublicRoute exact path="/login" component={Login} />
      <AppRouter />
    </Switch>
  );
}

export default RootRouter;
