import { Api } from "../config/request";

export const getMessageList = (payload) => {
  return Api.postRequest(`admins/whatsapp`, payload);
};

const WhatsAppApi = {
  getMessageList,
};

export default WhatsAppApi;
