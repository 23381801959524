import { combineReducers } from "redux";
import User from "./user";
import Login from "./login";
import Group from "./group";
import Ride from "./ride";
import Whatsapp from "./whatsapp";
import Carousel from "./carousel";
export default combineReducers({
  User,
  Group,
  Ride,
  Login,
  Whatsapp,
  Carousel,
});
