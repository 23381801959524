import React from "react";
import {
  PaginationWrap,
  Text,
  PaginationButton,
  Prev,
  Next,
} from "./pagination.styled";

function Pagination({
  page,
  pagination: { count, totalCount, prev, next, pageSize },
  updatePage,
}) {
  let firstItem = page * pageSize + 1;
  let lastItem = page * pageSize + count;
  if(isNaN(firstItem)) firstItem = count > 0 ? 1 : 0;
  if(isNaN(lastItem)) lastItem = count;

  return (
    <PaginationWrap>
      <Text>
        Viewing{" "}
        <span>
          {firstItem}-{lastItem}
        </span>{" "}
        of {totalCount}
      </Text>
      <PaginationButton>
        <Prev
          active={!!prev}
          onClick={() => {
            if (!!prev) {
              updatePage(prev);
            }
          }}
        >
          <i></i>
        </Prev>
        <Next
          active={!!next}
          onClick={() => {
            if (!!next) {
              updatePage(next);
            }
          }}
        >
          <i></i>
        </Next>
      </PaginationButton>
    </PaginationWrap>
  );
}

export default Pagination;
