const initialState = {
  groupList: [],
  filters: {
    page: 0,
    sort: {
      createdAt : "desc",
    }
  },
  pagination: {
    count: 0,
    totalCount: 0,
    prev: null,
    next: null,
    pageSize: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_GROUP_LIST":
      return setGroupList(state, action);
    case "UPDATE_GROUP_FILTER":
      return updateGroupFilter(state, action);
    case "SET_GROUP_FILTER":
      return setGroupFilter(state, action);
    default:
      return state;
  }
}

function setGroupList(state, action) {
  return {
    ...state,
    groupList: action.payload ? action.payload.data : initialState.groupList,
    pagination: action.payload
      ? action.payload.pagination
      : initialState.pagination,
  };
}

function updateGroupFilter(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  };
}

function setGroupFilter(state, action) {
  return {
    ...state,
    filters: action.payload ? action.payload : initialState.filters,
  };
}
