import { Tag } from "antd";
import React from "react";
import {
  SearchWrap,
  SmallButton,
  TagWrap,
  SearchContainer,
} from "static/coreStyles/global.styled";
import { omit, toString } from "lodash";

export function Search({
  setSearchTerm,
  onFilterClick,
  filters = {},
  setFilter,
}) {
  const filtersToUse = omit(filters, ["page", "query", "sort"]);
  return (
    <SearchContainer>
      <SearchWrap>
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <img src={require("static/images/search.svg")} alt="" />
      </SearchWrap>
      <SmallButton onClick={onFilterClick}>
        <img src={require("static/images/filter.svg")} alt="" />
      </SmallButton>
      <TagWrap>
        {Object.keys(filtersToUse).map((filter) => {
          const name = filter.replace(/([A-Z])/g, " $1").trim();
          return (
            <Tag
              key={filter}
              closable
              style={{ textTransform: "capitalize" }}
              onClose={() => {
                const updatedFilters = omit(filters, filter);
                setFilter(updatedFilters);
              }}
            >
              {name} : {toString(filters[filter])}
            </Tag>
          );
        })}
      </TagWrap>
    </SearchContainer>
  );
}
