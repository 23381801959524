import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button, Form, Input, Upload } from "antd";
import {
  addCarouselSlider,
  getCarouselList,
  getImageLink,
} from "actions/carousel";

const AddSliderModal = ({
  visible,
  setVisible,
  fetchCarouselList,
  fetchImageLink,
  currCarouselId,
  addSlider,
  filters,
}) => {
  const [file, setFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const formData = new FormData();
    formData.append("file", imageFile);

    const { data } = await fetchImageLink(formData);

    if (data?.fileLink) {
      values["imageLink"] = data.fileLink;
      await addSlider(currCarouselId, values);
      form.resetFields();
      setFile(null);
      setImageFile(null);
      setVisible(false);
      fetchCarouselList(filters);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title="Add Slider"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[]}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 400,
        }}
        // initialValues={{
        //   remember: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Seq No."
          name="seqNum"
          rules={[
            {
              required: true,
              message: "Please input sequence number!",
            },
          ]}
        >
          <Input placeholder="4" />
        </Form.Item>

        <Form.Item
          label="Slider Name"
          name="sliderName"
          rules={[
            {
              required: true,
              message: "Please input slider name!",
            },
          ]}
        >
          <Input placeholder="Test Slider" />
        </Form.Item>

        <Form.Item
          label="Upload Image"
          name="imageLink"
          rules={[
            {
              required: imageFile ? false : true,
              message: "Please upload slider image!",
            },
          ]}
          valuePropName="file"
          getValueFromEvent={(event) => {
            return event?.fileList[0];
          }}
        >
          <Upload
            maxCount={1}
            listType="picture"
            accept="image/jpg"
            customRequest={(e) => {
              setImageFile(e.file);
              const imageUrl = URL.createObjectURL(e.file);
              setFile(imageUrl);
            }}
            showUploadList={false}
          >
            <Button>Click here to Upload</Button>
          </Upload>
          {/* <input
            type="file"
            name="file"
            onChange={handleChange}
            accept="image/jpeg"
          /> */}
          {file && (
            <img src={file} width={200} height={100} alt="Slider_Image" />
          )}
        </Form.Item>

        <Form.Item
          label="URL Link"
          name="urlLink"
          rules={[
            {
              required: true,
              message: "Please input URL link!",
            },
          ]}
        >
          <Input placeholder="http://google.com" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 12,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapStateToProps(state) {
  const {
    Carousel: { filters, currCarouselId },
  } = state;
  return {
    filters,
    currCarouselId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCarouselList: bindActionCreators(getCarouselList, dispatch),
    fetchImageLink: bindActionCreators(getImageLink, dispatch),
    addSlider: bindActionCreators(addCarouselSlider, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSliderModal);
