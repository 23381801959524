import { Button } from "antd";
import React from "react";
import { Td, TableList } from "static/coreStyles/table.styled";

export const CarouselList = ({ data, handleDelete, handleEdit }) => {
  const { id, seqNum, sliderName, imageLink, urlLink } = data;
  if (!id) return null;

  return (
    <TableList>
      <Td width="10%">
        <div>{seqNum && <span>{seqNum}</span>}</div>
      </Td>
      <Td width="20%">
        <div className="info">{sliderName && <span>{sliderName}</span>}</div>
      </Td>
      <Td width="15%">
        {imageLink && (
          <span>
            <a href={imageLink} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </span>
        )}
      </Td>
      <Td width="15%">
        {urlLink && (
          <span>
            <a href={urlLink} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </span>
        )}
      </Td>
      <Td width="15%">
        <span onClick={() => handleEdit(data)}>
          <Button>Edit</Button>
        </span>
      </Td>
      <Td width="15%">
        <span onClick={() => handleDelete(data)}>
          <Button>Delete</Button>
        </span>
      </Td>
    </TableList>
  );
};
