import React from "react";
import { Th } from "static/coreStyles/table.styled";

export default function TableHead ({ data: { title, width }, sort, isSorted, handleSort }) {
  return (
    <Th sort={sort} width={width || '25%'} {...(isSorted ? { onClick: handleSort } : { })} isActive={isSorted && sort !== null}>
      {title}{" "}
      {isSorted && ['desc', 'asc'].includes(sort) && (
        <img
          src={
            sort === "asc"
              ? require("static/images/sort_selected.svg")
              : require("static/images/Sort_unselected.svg")
          }
          alt="sort"
        />
      )}
    </Th>
  );
};
