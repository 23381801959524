export const tableHead = [
  {
    title: "Seq No",
    width: "10%",
    sortKey: "seqNum",
  },
  {
    title: "Slider Name",
    width: "20%",
  },
  {
    title: "Image Link",
    width: "15%",
  },
  {
    title: "URL Link",
    width: "15%",
  },
  {
    title: "Edit Slider",
    width: "15%",
  },
  {
    title: "Delete Slider",
    width: "15%",
  },
];

export const userList = [
  {
    seqNum: "2",
    sliderName: "abcd",
    imageLink: "https://example.com/slider-image.jpg",
    urlLink: "https://example.com/slider-link",
    edit: "Edit",
    delete: "Delete",
  },
  {
    seqNum: "5",
    sliderName: "xyz",
    imageLink: "https://example.com/slider-image.jpg",
    urlLink: "https://example.com/slider-link",
    edit: "Edit",
    delete: "Delete",
  },
];
