import LoginApi from "services/login";
import { storage as LocalStorage } from "services/config/storage";
import { message } from "antd";
import swalState from "utility/swal";
import { logout } from "utility/helperFunctions";

import { getGroupList } from "actions/group";
import { getRideList } from "actions/ride";
import { getMessageListAction } from "actions/whatsapp";
import { getCarouselList } from "actions/carousel";

function handleError(err) {
  console.error("Error in login actions: ", err);
}

export function setCurrentUser(payload) {
  return {
    type: "SET_CURRENT_USER",
    payload,
  };
}

export const userLogin = (payload, props) => async (dispatch) => {
  try {
    swalState.showLoader();
    const response = await LoginApi.userLogin(payload);
    const data = response?.data;
    if (data) {
      const { token } = data;
      LocalStorage.set.authToken(token);
      dispatch(setCurrentUser(data));
    }
    Promise.all([
      dispatch(getGroupList()),
      dispatch(getRideList()),
      dispatch(getMessageListAction()),
      dispatch(getCarouselList({ location: "home", name: "e-commerce" })),
    ]);
    return response;
  } catch (err) {
    message.error("Invalid Credentials", 4);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};

export const userLogout = (history) => async (dispatch) => {
  try {
    swalState.showLoader();
    const response = await LoginApi.userLogout();
    return response;
  } catch (err) {
    handleError(err);
  } finally {
    swalState.hideLoader();
    logout(history);
  }
};
