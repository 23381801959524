export const HeaderTitles = [
  {
    title: "Users",
    url: "/users",
    activeIcon: require("static/images/user-active.svg"),
    inactiveIcon: require("static/images/user.svg"),
  },
  {
    title: "Rides",
    url: "/rides",
    activeIcon: require("static/images/ride-active.svg"),
    inactiveIcon: require("static/images/ride.svg"),
  },
  {
    title: "Groups",
    url: "/groups",
    activeIcon: require("static/images/groups-active.svg"),
    inactiveIcon: require("static/images/groups.svg"),
  },
  {
    title: "Whatsapp",
    url: "/whatsapp",
    activeIcon: require("static/images/whatsapp-active.svg"),
    inactiveIcon: require("static/images/whatsapp.svg"),
  },
  {
    title: "Carousel",
    url: "/carousel",
    activeIcon: require("static/images/carousel.svg"),
    inactiveIcon: require("static/images/carousel.svg"),
  },
];
