export const tableHead = [
  {
    title: "Sender Name",
    width: "15%",
    sortKey: "SenderName",
  },
  {
    title: "Sender Phone Number",
    width: "15%",
    sortKey: "SenderPhoneNumber",
  },
  {
    title: "Message Date & Time",
    sortKey: "messageTime",
    width: "15%",
  },
  {
    title: "Type",
    width: "15%",
  },
  {
    title: "Message",
    width: "35%",
  },
  {
    title: "Flow",
    width: "10%",
  },
];
