import styled from "styled-components";
import { Font, Theme } from "static/coreStyles/theme.styled";

export const PaginationWrap = styled.div`
  display: flex;
  width: 175px;
  align-items: center;
  right: 40px;
  position: absolute;
  bottom: 40px;
`;
export const Text = styled.div`
  font-family: Cabin;
  font-size: 12px;
  color: ${Theme.greyColor};
  font-family: ${Font.regular};
  span {
    font-weight: 700;
    font-family: ${Font.bold};
    color: ${Theme.blueColor};
  }
`;

export const PaginationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;
  margin-left: 15px;
  > div {
    width: 26px;
    height: 26px;
    background-color: rgba(112, 112, 112, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
export const Prev = styled.div`
  background-color: ${(props) =>
    props.active ? Theme.blueColor : "rgba(112, 112, 112,0.2)"} !important;
  i {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 7px solid #fff;
    border-bottom: 5px solid transparent;
    border-radius: 4px;
  }
`;

export const Next = styled.div`
  background-color: ${(props) =>
    props.active ? Theme.blueColor : "rgba(112, 112, 112,0.2)"} !important;
  i {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 8px solid #fff;
    border-bottom: 5px solid transparent;
    border-radius: 4px;
  }
`;
