import styled from "styled-components";
import { Font, Theme } from "static/coreStyles/theme.styled";
import { RIDE_STATUS } from "utility/constants";

export const TableWrap = styled.div`
  padding: 0 0 30px;
`;

export const Th = styled.div`
  color: ${props => props.isActive? Theme.blueColor: Theme.greyColor };
  /* :first-child {
    width: 15%;
  }
  :nth-child(2) {
    width: 15%;
  }
  :nth-child(3) {
    width: 15%;
  }
  :nth-child(4) {
    width: 15%;
  }
  :nth-child(5) {
    width: 10%;
  }
  :nth-child(6) {
    width: 8%;
  }
  :nth-child(7) {
    width: 10%;
  }
  :last-child {
    width: 12%;
  } */
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  width: ${props => props.width};
  img {
    margin: -3px 0 0 5px;
    cursor: pointer;
  }
`;
export const TableHeadWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 10px;
  background-color: #f6f7fb;
  z-index: 9;
  &.user-table-head {
    > div {
      /* :first-child {
        width: 20%;
      }
      :nth-child(2) {
        width: 15%;
      }
      :nth-child(3) {
        width: 12%;
      }
      :nth-child(4) {
        width: 8%;
      }
      :nth-child(5) {
        width: 15%;
      }
      :nth-child(6) {
        width: 10%;
      }
      :nth-child(7) {
        width: 12%;
      }
      :last-child {
        width: 8%;
      } */
    }
  }
  &.group-table-head {
    > div {
      /* :first-child {
        width: 16%;
      }
      :nth-child(2) {
        width: 15%;
      }
      :nth-child(3) {
        width: 12%;
      }
      :nth-child(4) {
        width: 14%;
      }
      :nth-child(5) {
        width: 11%;
      }
      :nth-child(6) {
        width: 12%;
      }
      :nth-child(7) {
        width: 10%;
      }
      :last-child {
        width: 8%;
      } */
    }
  }
`;

export const TableContentWrap = styled.div``;
export const TableList = styled.div`
  min-height: 80px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
`;

export const Td = styled.div`
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-family: ${(props) => (props.bold ? `${Font.bold}` : `${Font.regular}`)};
  text-transform: capitalize;
  span {
    display: block;
    max-width: 240px;
    font-size: 14px;
    line-height: 1;
  }
  em {
    font-size: 12px;
    color: ${Theme.greyColor};
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    i {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }
  .img-wrap {
    width: 80px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f7fb;
    img,
    video {
      max-width: 100%;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .anticon {
    cursor: pointer;
  }
  &.edit-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    .anticon {
      margin-left: 10px;
    }
  }

  .profile {
    width: 27px;
    height: 27px;
    background-color: #6528d8;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;
    font-family: ${Font.bold};
    border-radius: 50%;
    padding: 6px 0 0;
    text-align: center;
    font-style: normal;
    margin-right: 10px;
  }
  .info {
    display: flex;
    align-items: center;
  }
`;
export const Block = styled.span`
    justify-content: center;
    text-align: center;
    font-size: 14px;
`;
export const Status = styled.div`
  min-width: 77px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.status === RIDE_STATUS[2]
      ? `rgba(54, 179, 126,0.1)`
      : props.status === RIDE_STATUS[1]
      ? "rgba(101, 40, 216,0.1)"
      : props.status === RIDE_STATUS[0]
      ? "#c7f9ff"
      : "rgba(252, 105, 68,0.1)"};
  display: inline-block;
  padding: 3px 8px 1px;
  font-size: 11px;
  font-weight: bold;
  color: ${(props) =>
    props.status === RIDE_STATUS[2]
      ? `rgb(54, 179, 126)`
      : props.status === RIDE_STATUS[1]
      ? "rgba(101, 40, 216,1)"
      : props.status === RIDE_STATUS[0]
      ? "#3292dc"
      : "rgba(252, 105, 68,1)"};
  text-transform: uppercase;
  text-align: center;
`;

