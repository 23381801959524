import { Api } from "../config/request";

export const getRideList = (payload) => {
  return Api.postRequest(`admins/rides`, payload);
};

const RideAPI = {
  getRideList
};

export default RideAPI;
