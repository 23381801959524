export const tableHead = [
  {
    title: "User details",
    sortKey: 'name',
    width: '20%',
  },
  {
    title: "Number",
    width: '15%',
  },
  {
    title: "Join Date",
    sortKey: 'createdAt',
    width: '12%',
  },
  {
    title: "Age",
    sortKey: 'age',
    width: '8%',
  },
  {
    title: "Blood Grp",
    width: '10%',
  },
  {
    title: "Groups",
    sortKey: "groups",
    width: '10%',
  },
  {
    title: "Completed Rides",
    sortKey: "rides",
    width: '10%',
  },
  {
    title: "Total Joined Rides",
    sortKey: "totalRides",
    width: '10%',
  },
  {
    title: "Country",
    sortKey: "alphaCode",
    width: '12%',
  },
  {
    title: "Bikes",
    width: '8%',
  },
];
