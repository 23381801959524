import React from "react";
import { ButtonWrap, SmallButton } from "static/coreStyles/global.styled";

export default function ({ onClickExport, onClickColumn }) {
  return (
    <ButtonWrap>
      {onClickColumn && (
        <SmallButton primaryOutline onClick={onClickColumn}>
          <img src={require("static/images/poll.svg")} alt="" />
        </SmallButton>
      )}
      {onClickExport && (
        <SmallButton primary onClick={onClickExport}>
          <img src={require("static/images/file-download.svg")} alt="" />
        </SmallButton>
      )}
    </ButtonWrap>
  );
}
