import React, { useEffect, useState } from "react";
import {
  Container,
  PageHeader,
  TitleHead,
  PageWrap,
  Button,
  FilterContainer,
  Filter,
  FilterTitle,
} from "static/coreStyles/global.styled";
import TableHead from "components/TableHead";
import { tableHead } from "./data";
import {
  TableWrap,
  TableHeadWrap,
  TableContentWrap,
} from "static/coreStyles/table.styled";
import { CarouselList } from "./CarouselList/CarouselList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmptyState from "components/emptyState";
// import Pagination from "components/pagination";
import { getCarouselList, setFilters, updateFilters } from "actions/carousel";
import { useDebounce } from "utility/helperFunctions";
import { Modal, Dropdown, Menu } from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import Search from "components/Search";
import { omit } from "lodash";
import { HeaderButtons } from "components/Header";
import carouselFilterData from "static/data/carouselFilter.json";
import DeleteSliderModal from "components/Modals/deleteSlider";
import EditSliderModal from "components/Modals/editSlider";
import AddSliderModal from "components/Modals/addSlider";

function Carousel({
  carouselList,
  fetchCarouselList,
  updateCarouselFilter,
  setCarouselFilter,
  filters,
  //   pagination,
}) {
  const sortMap = filters.sort || {};
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteSliderData, setDeleteSliderData] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editSliderData, setEditSliderData] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  useEffect(() => {
    if (debouncedSearchTerm) {
      updateCarouselFilter({
        query: debouncedSearchTerm,
      });
    } else {
      updateCarouselFilter({
        query: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleSort = (key, value) => {
    if (value === "") {
      const sortKeys = omit(filters.sort, key);
      updateCarouselFilter({
        sort: sortKeys,
      });
    } else {
      updateCarouselFilter({
        sort: {
          ...(filters.sort ? filters.sort : {}),
          [key]: value,
        },
      });
    }
  };

  const handleDelete = (slider) => {
    setDeleteSliderData(slider);
    setDeleteModalVisible(true);
  };

  const handleEdit = (slider) => {
    setEditSliderData(slider);
    setEditModalVisible(true);
  };

  const handleAdd = () => {
    setAddModalVisible(true);
  };

  useEffect(() => {
    fetchCarouselList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <PageWrap>
      <Container>
        <PageHeader>
          <TitleHead>
            <Search
              setSearchTerm={setSearchTerm}
              onFilterClick={() => {
                setModalVisible(true);
              }}
              filters={filters}
              setFilter={setCarouselFilter}
            />
            <HeaderButtons />
            <Button onClick={() => handleAdd()}>Add Slider</Button>
          </TitleHead>
        </PageHeader>
        {carouselList && carouselList.length > 0 ? (
          <TableWrap>
            <TableHeadWrap className="group-table-head">
              {tableHead.map((item, index) => {
                const sortingKey =
                  item.sortKey &&
                  sortMap &&
                  sortMap.hasOwnProperty(item.sortKey)
                    ? sortMap[item.sortKey]
                    : null;
                return (
                  <TableHead
                    data={item}
                    key={index}
                    isSorted={!!item.sortKey}
                    handleSort={() => {
                      if (sortingKey === "desc") {
                        handleSort(item.sortKey, "asc");
                      } else if (sortingKey === "asc") {
                        handleSort(item.sortKey, "");
                      } else {
                        handleSort(item.sortKey, "desc");
                      }
                    }}
                    sort={sortingKey}
                  />
                );
              })}
            </TableHeadWrap>
            <TableContentWrap>
              {carouselList.map((item, index) => {
                return (
                  <CarouselList
                    data={item}
                    key={index}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                );
              })}
            </TableContentWrap>
          </TableWrap>
        ) : (
          <EmptyState />
        )}
        {/* <Pagination
          page={filters.page}
          pagination={pagination}
          updatePage={(newPage) => {
            updateCarouselFilter({ page: newPage });
          }}
        /> */}
        <FilterModal
          filters={filters}
          visible={modalVisible}
          setVisible={setModalVisible}
          setFilter={setCarouselFilter}
          updateFilter={updateCarouselFilter}
        />
        <DeleteSliderModal
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          slider={deleteSliderData}
        />
        <EditSliderModal
          visible={editModalVisible}
          setVisible={setEditModalVisible}
          slider={editSliderData}
        />
        <AddSliderModal
          visible={addModalVisible}
          setVisible={setAddModalVisible}
        ></AddSliderModal>
      </Container>
    </PageWrap>
  );
}

function FilterModal({ visible, setVisible, setFilter }) {
  const [location, setLocation] = useState("Home");
  const [name, setName] = useState(carouselFilterData["Home"][0]);

  const locationMenuItems = [];
  Object.keys(carouselFilterData).forEach((key) => {
    locationMenuItems.push(
      <Menu.Item
        onClick={() => {
          setLocation(key);
          setName(carouselFilterData[key][0]);
        }}
      >
        {key}
      </Menu.Item>
    );
  });

  const nameMenuItems = [];
  carouselFilterData[location].forEach((element) => {
    nameMenuItems.push(
      <Menu.Item
        onClick={() => {
          setName(element);
        }}
      >
        {element}
      </Menu.Item>
    );
  });

  const locationMenu = <Menu>{locationMenuItems}</Menu>;

  const nameMenu = <Menu>{nameMenuItems}</Menu>;

  return (
    <Modal
      title="Apply Filter"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="submit"
          primary
          color={Theme.blueColor}
          onClick={() => {
            const filterData = {};
            if (location) {
              filterData["location"] = location;
            }

            if (name) {
              filterData["name"] = name;
            }

            setFilter(filterData);
            setVisible(false);
          }}
        >
          Apply
        </Button>,
      ]}
    >
      <FilterContainer>
        <Filter>
          <FilterTitle>Location</FilterTitle>
          <Dropdown overlay={locationMenu}>
            <span>{location}</span>
          </Dropdown>
        </Filter>
        <Filter>
          <FilterTitle>Name</FilterTitle>
          <Dropdown overlay={nameMenu}>
            <span>{name}</span>
          </Dropdown>
        </Filter>
      </FilterContainer>
    </Modal>
  );
}
function mapStateToProps(state) {
  const {
    Carousel: { carouselList, filters, pagination },
  } = state;
  return {
    carouselList,
    filters,
    pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCarouselList: bindActionCreators(getCarouselList, dispatch),
    setCarouselFilter: bindActionCreators(setFilters, dispatch),
    updateCarouselFilter: bindActionCreators(updateFilters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
