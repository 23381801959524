export const tableHead = [
  {
    title: "Ride Creator",
    width: "14%",
  },
  {
    title: "Ride Name",
    sortKey: "name",
    width: "14%",
  },
  {
    title: "Creation Date",
    sortKey: "createdAt",
    width: "10%",
  },
  {
    title: "Start Date",
    sortKey: "startDateTime",
    width: "10%",
  },
  {
    title: "From → To",
    width: "14%",
  },
  {
    title: "Count",
    sortKey: "riderCount",
    width: "6%",
  },
  {
    title: "Limit",
    width: "6%",
  },
  {
    title: "Price",
    width: "6%",
  },
  {
    title: "Status",
    width: "8%",
  },
  {
    title: "Public",
    width: "8%",
  },
  {
    title: "Action",
    sortKey: "",
    width: "5%",
    textAlign: "center",
  },
];

export const userList = [
  {
    rideCreator: "Lia Castro",
    rideCreatorOrg: "Lia_Castro_Rider",
    rideName: "Neemrana Ride",
    startDate: "27 Jul’ 20",
    from: "New Delhi",
    to: "Neemrana",
    count: "12",
    limit: "200",
    price: "free",
    status: "1",
  },
  {
    rideCreator: "Lia Castro",
    rideCreatorOrg: "Lia_Castro_Rider",
    rideName: "Neemrana Ride",
    startDate: "27 Jul’ 20",
    from: "New Delhi",
    to: "Neemrana",
    count: "12",
    limit: "200",
    price: "free",
    status: "2",
  },
  {
    rideCreator: "Lia Castro",
    rideCreatorOrg: "Lia_Castro_Rider",
    rideName: "Neemrana Ride",
    startDate: "27 Jul’ 20",
    from: "New Delhi",
    to: "Neemrana",
    count: "12",
    limit: "200",
    price: "free",
    status: "3",
  },
];
