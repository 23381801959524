import swalState from "utility/swal";
import { message } from "antd";
import GroupAPI from "services/group";
import { removeEmptyValues } from "utility/helperFunctions";

function handleError(err) {
  console.error("Error in users actions: ", err);
}

export function setGroupList(payload) {
  return {
    type: "SET_GROUP_LIST",
    payload,
  };
}

export function updateGroupFilter(payload) {
  return {
    type: "UPDATE_GROUP_FILTER",
    payload,
  };
}

export function setGroupFilter(payload) {
  return {
    type: "SET_GROUP_FILTER",
    payload,
  };
}

export const getGroupList = (filters) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await GroupAPI.getGroupList(removeEmptyValues(filters));
    
    if (response) {
      const { data, count, totalCount, prev, next, pageSize } = response;
      dispatch(setGroupList({
        data,
        pagination: {
          count,
          totalCount,
          prev,
          next,
          pageSize,
        },
      }));
    }
  } catch (err) {
    message.error(err.message);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};

export const updateGroupDetails = (id, updates) => async (dispatch) => {
  try {
    swalState.showLoader();
    const response = await GroupAPI.updateGroupDetails(id, updates);
    if (response) {
      message.success("Group details updated successfully");
      dispatch(getGroupList());
    }
  } catch (err) {
    message.error(err.message);
    handleError(err);
  } finally {
    swalState.hideLoader();
  }
};


export const updateFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(updateGroupFilter(update));
  } catch (err) {
    handleError(err);
  }
};

export const setFilters = (update) => async (dispatch) => {
  try {
    swalState.hideLoader();
    dispatch(setGroupFilter(update));
  } catch (err) {
    handleError(err);
  }
};
