import styled from "styled-components";
import { Theme, Size, Font } from "static/coreStyles/theme.styled";

export const HeaderWrap = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${Theme.white};
  padding: 15px 0;
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderTitle = styled.div`
  margin-right: 48px;
  font-weight: bold;
  position:relative;
  :last-child {
    margin-right: 0;
  }
  .nav-link {
    font-size: ${Size.xs};
    text-transform: capitalize;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    i {
      margin-right: 7px;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      img {
        display: block;
        width: 100%;
      }
    }
    span {
      color: ${(props) =>
        props.active ? `${Theme.primaryColor}` : `${Theme.greyColor}`};
      font-size: 16px;
    }
    small {
      background-color: rgba(112, 112, 112, 0.1);
      border-radius: 11.5px;
      display: inline-block;
      padding: 2px 8px;
      margin-left: 10px;
      color: ${(props) =>
        props.active ? `${Theme.blueColor}` : `${Theme.greyColor}`};
        font-family:${Font.bold};
        font-weight:700;
        background-color: ${(props) =>
            props.active ? `rgba(101, 40, 216, 0.1)` : `rgba(112, 112, 112, 0.1)`};
    }
  }
  :after{
      content:"";
      width:${(props) =>
        props.active ? `110%` : `0`};
      background-color:${Theme.blueColor};
      height:4px;
      position:absolute;
      bottom:-20px;
      left:0;
      right:0;
      margin:0 auto;
  }
`;
export const SubTitleWrap = styled.div`
  position: fixed;
  top: 60px;
  width: 100%;
  left: 0;
  background-color: ${Theme.white};
  cursor: default;
`;
export const SubTitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1030px;
  padding: 0 70px 0 180px;
  margin: 0 auto;
`;

export const SubTitleLink = styled.div`
  padding: 12px 0;
  margin-right: 40px;
  cursor: pointer;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    color: ${Theme.blueColor};
    &:after {
      width: 70%;
      height: 2px;
      background-color: ${Theme.blueColor};
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
    }
  }
`;
export const Logo = styled.img`
  width: auto;
`;
export const Titles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ProfileContainer = styled.div`
cursor:pointer;
  .ant-avatar,img {
    width: 30px;
    height: 30px;
    background-color: ${Theme.blueColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: ${Theme.white};
    border-radius: 50%;
    font-family: ${Font.bold};
    span {
      transform: none !important;
      position: static;
    }
  }
`;

export const LogoutWrap = styled.div`
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: ${Theme.blueColor};
    font-family: ${Font.bold};
`;

export const TitleWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
    h3 {
      font-family: ${Font.bold};
    }
    h4 {
      /* font-family: ${Font.bold}; */
    }
`;
