import React from "react";
import {EmptyStateWrap} from "./emptyState.styled";


const EmptyState = () => {
    return (
        <EmptyStateWrap>
            <span>no data</span>
        </EmptyStateWrap>
    )
}

export default EmptyState;