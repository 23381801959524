import React from "react";
import { TableList, Td } from "static/coreStyles/table.styled";

import { formatToDateTimeString } from "utility/helperFunctions";

const MessagesList = ({ data }) => {
  if (!data.id) return null;
  return (
    <TableList>
      {" "}
      <Td width="15%">{data.senderName}</Td>{" "}
      <Td width="15%">{data.senderPhoneNumber}</Td>
      <Td width="15%">{formatToDateTimeString(data.messageTime)}</Td>
      <Td width="15%">{data.messageType}</Td>
      <Td width="35%">{data.messageContent}</Td>
      <Td width="10%">{data.messageFlow}</Td>
    </TableList>
  );
};

export default MessagesList;
