import { Font } from "static/coreStyles/theme.styled";
import { Theme } from "static/coreStyles/theme.styled";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const Title = styled.span`
  display: flex;
  font-size: 32px;
  font-family: ${Font.bold};
`;

export const Subtitle = styled.span`
  font-family: ${Font.bold};
  display: flex;
  min-width: 44px;
  min-height: 44px;
  border-radius: 6px;
`;

export const GoBack = styled.a`
  font-family: ${Font.bold};
  color: ${Theme.blueColor};
  display: flex;
  text-align: center;
  :hover{
    color: ${Theme.primaryColor};
  }
`;
