import React, { useEffect, useState } from "react";
import {
  Container,
  PageHeader,
  TitleHead,
  PageWrap,
} from "static/coreStyles/global.styled";
import {
  TableWrap,
  TableContentWrap,
} from "static/coreStyles/table.styled";
import { RideListData } from "./RideListData/RideListData";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmptyState from "components/emptyState";
import Pagination from "components/pagination";
import { getRideList } from "actions/ride";
import { HeaderButtons } from "components/Header";

function Rides({
  rideList,
  fetchRideList,
  filters,
  pagination,
}) {
  useEffect(() => {
    fetchRideList(filters);
  }, [filters, fetchRideList]);

  return (
    <PageWrap>
      <Container>
        <PageHeader>
          <TitleHead>
            <HeaderButtons />
          </TitleHead>
        </PageHeader>
        {rideList && rideList.length > 0 ? (
          <TableWrap>
            <TableContentWrap>
              {rideList.map((item, index) => (
                <RideListData data={item} key={index} />
              ))}
            </TableContentWrap>
          </TableWrap>
        ) : (
          <EmptyState />
        )}
        <Pagination
          page={filters.page}
          pagination={pagination}
          updatePage={(newPage) => fetchRideList({ ...filters, page: newPage })}
        />
      </Container>
    </PageWrap>
  );
}

function mapStateToProps(state) {
  const {
    Ride: { rideList, filters, pagination },
  } = state;
  return {
    rideList,
    filters,
    pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRideList: bindActionCreators(getRideList, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Rides);
