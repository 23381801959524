import React, { Component } from "react";

import { Container } from "./login.styled";
import { LoginContainer, FormContainer } from "./login.styled";
import {
  FlexBox,
  Form,
  FormItem,
  ButtonWrap,
  Button,
} from "static/coreStyles/global.styled";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginActions from "actions/login";
import { validateEmail } from "utility/helperFunctions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: "",
      passwordError: "",
      email: "",
      password: "",
    };
  }
  //renders login screen
  handleInputChange = (key, value) => {
    this.setState({ [key]: value[0] !== " " ? value : value.trim() }, () => {
      if (
        key === "email" &&
        this.state.email !== "" &&
        validateEmail(this.state.email)
      ) {
        this.setState({ emailError: "" });
      } else if (key === "password" && this.state.password !== "") {
        this.setState({ passwordError: "" });
      }
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    let isValidatedEmail = validateEmail(this.state.email);
    if (this.state.email === "") {
      await this.setState({ emailError: "Enter email id." });
    } else {
      await this.setState({ emailError: "" });
    }
    if (this.state.password === "") {
      await this.setState({ passwordError: "Password cannot be empty." });
    } else {
      await this.setState({ passwordError: "" });
    }
    if (this.state.email !== "" && !isValidatedEmail) {
      await this.setState({ emailError: "Enter valid email." });
    } else if (!this.state.passwordError && !this.state.emailError) {
      let obj = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.userLogin(obj).then((response) => {

        // if (response) {
          this.props.history.push("/");
        // }
      });
    }
  };
  render() {
    return (
      <Container>
        <LoginContainer>
          <img alt="cover-img" src={require("static/images/cover-img.jpg")} />
          <FormContainer>
            <img
              className="logo"
              alt="login-logo"
              src={require("static/images/login-logo.svg")}
            />
            <FlexBox start={1} column className="welcome-text">
              <h2>Hello, Welcome back</h2>
              <span>Please login to your account</span>
            </FlexBox>
            <Form>
              <FormItem>
                <label>Email ID</label>
                <input
                  type="text"
                  placeholder="Enter email id"
                  onChange={(e) =>
                    this.handleInputChange("email", e.target.value)
                  }
                />
                {this.state.emailError !== "" && (
                  <span className="error-msg">{this.state.emailError}</span>
                )}
              </FormItem>
              <FormItem>
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) =>
                    this.handleInputChange("password", e.target.value)
                  }
                />
                {this.state.passwordError !== "" && (
                  <span className="error-msg">{this.state.passwordError}</span>
                )}
              </FormItem>
              <ButtonWrap className="login-btn-wrap">
                <Button primary onClick={this.handleSubmit}>Login</Button>
              </ButtonWrap>
            </Form>
          </FormContainer>
        </LoginContainer>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: bindActionCreators(loginActions.userLogin, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
