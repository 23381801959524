import styled from "styled-components";
import { FlexBox ,FilterWrap} from "static/coreStyles/global.styled";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer = styled(FlexBox)`
width: fit-content;
height: 80%;
img{
  height: 100%;
}
`;

export const FormContainer = styled(FilterWrap)`
align-items : flex-start;
flex-direction: column;
padding: 50px 60px;
min-width: 500px;
justify-content:center;
height: 100%;
.logo{
  width: 130px;
  height: auto;
  margin-bottom: 50px;
}
.welcome-text{
  margin-bottom : 40px;
  h2{
    font-weight : bold;
    line-height: 0.83;
    color: #131415;
  }
  span{
    color: #7a869a;
  }
}
`;
